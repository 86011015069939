import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { richTextConfig } from "../rich-text-config/rich-text-config";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SwiftType from "../components/swift-type";
import HeaderSolidColor from "../modules/headers/header-solid-color";
import SectionHeading from "../components/section-heading";
import moduleConductor from "../modules/module-conductor";
import ModuleCarousel from "../modules/module-carousel";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const TemplateBoardListing = ({ data, location, pageContext }) => {
  const {
    contentfulTemplateListingPage: page,
    allContentfulTemplateLeadership: leadership,
  } = data;

  const metaTitle = page?.seoPageTitle || page?.pageTitle;
  const metaDesc = page.pageDescription
    ? page.pageDescription.pageDescription
    : null;
  const metaImage = page?.seoShareImage?.file?.url;

  // Separate out people in a map by role category
  // let ceo = null;
  const folks = {};
  leadership.nodes.map((person, i) => {
    if (folks.hasOwnProperty(person.partOf)) {
      folks[person.partOf].push(person);
    } else {
      folks[person.partOf] = [];
      folks[person.partOf].push(person);
    }
  });
  // console.log("folks: ", folks);
  let leadershipListingMarkup = []; // The entire JSX markup for all of the people grids
  let counter = 1; // Keep track of every other list for stylng purpose

  for (const [key, value] of Object.entries(folks)) {
    // console.log(`${key}: ${value}`);
    let title = <SectionHeading headline={key} key={key} />;
    let listOfPeople = [];

    leadershipListingMarkup.push(title);

    for (let i = 0; i < value.length; i++) {
      const person = value[i];

      let peopleCard = (
        <Link
          className="leadership-card"
          to={person.slug}
          key={"leadership" + i}
        >
          <div className="leadership-card__img-wrap">
            {person.picture && (
              <GatsbyImage
                className="leadership-card__img"
                image={getImage(person.picture.gatsbyImageData)}
                // style={{ position: "absolute" }}
                alt={person.picture.description || ""}
                title={person.picture.description || ""}
              />
            )}
          </div>
          <div className="leadership-card__content">
            <h3 className="leadership-card__name">{person.fullName}</h3>

            {person.jobTitle && (
              <div className="leadership-card__job-title">
                {documentToReactComponents(
                 JSON.parse(person.jobTitle.raw),
                  richTextConfig()
                )}
              </div>
            )}
          </div>
        </Link>
      );

      listOfPeople.push(peopleCard);
    }

    // % 2 => only even elements
    const cardWrapper = (
      <div
        className={`leadership-card__card-container ${
          counter % 2 === 0 ? `leadership-card__card-container--red` : ``
        }`}
      >
        {listOfPeople}
      </div>
    );
    leadershipListingMarkup.push(cardWrapper);
    counter++;
  }
  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");
    // document.getElementById("loader_wrap").classList.add("loaded");
  }, []);

  const Loader = () => {
    let loaderDiv = document.getElementById("loader");
    let delay = 0.004;

    for (let x = 0; x < 40; x++) {
      let width = x * 2.5 + "%";
      let div = document.createElement("div");
      loaderDiv.appendChild(div);
      div.style.left = width;
      div.style.top = 0;
      div.style.animationDelay = ((x - 20) * (x - 20) * delay) / 8 + "s";
    }
  };

  let withUpdatedSlug = [];
  if (pageContext.relatedPages) {
    pageContext.relatedPages.forEach((page, i) => {
      let obj = { ...page, slug: `../${page.slug}` };
      // service.slug = `../${service.slug}`;
      withUpdatedSlug.push(obj);
    });
  }

  const modules = page.modules
    ? page.modules.map((module, i) => {
        if (
          module.__typename === "ContentfulModuleCtaBanner" &&
          pageContext.relatedPages
        ) {
          // submenuLinks.push('Services');
          return (
            <React.Fragment>
              <div className="template-solution__related-services">
                <ModuleCarousel
                  cards={withUpdatedSlug}
                  moduleHeadline={"Learn More About UnitedLex"}
                  // submenuTitle={'Learn More'}
                />
              </div>

              {moduleConductor(module, i)}
            </React.Fragment>
          );
        }

        return moduleConductor(module, i);
      })
    : null;

  const ceo = data.ceo ? data.ceo.nodes[0] : null;

  return (
    <Layout location={location}>
      <SEO
        title={metaTitle}
        description={metaDesc}
        url={location.href}
        image={metaImage}
      />
      <SwiftType customTitle={metaTitle} customDesc={metaDesc} />
      <HeaderSolidColor
        headerTitle={page.pageTitle}
        headerDescription={
          page.pageDescription ? page.pageDescription.pageDescription : ""
        }
      />
      {/* {ceo && (
        <div className="template-leadership-listing__ceo-banner">
          <div className="template-leadership-listing__ceo-img-wrap">
            {ceo.picture && <Img fluid={ceo.picture.fluid} />}
          </div>
          <div className="template-leadership-listing__ceo-content-wrap">
            <blockquote>
              {ceo.sidebarCallout &&
                documentToReactComponents(
                  ceo.sidebarCallout.raw,
                  richTextConfig()
                )}
              <p>{ceo.fullName}</p>
              {ceo.jobTitle &&
                documentToReactComponents(ceo.jobTitle.raw, richTextConfig())}
            </blockquote>
          </div>
        </div>
      )} */}
      {leadershipListingMarkup}
      {pageContext.relatedPages && (
        <ModuleCarousel
          cards={withUpdatedSlug}
          moduleHeadline={"Learn More About UnitedLex"}
          // submenuTitle={'Learn More'}
        />
      )}
      {page.addCtaBanner && moduleConductor(page.addCtaBanner, 0)}
    </Layout>
  );
};

export default TemplateBoardListing;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulTemplateListingPage(slug: { eq: $slug }) {
      pageTitle
      doNotIndex
      pageDescription {
        pageDescription
      }
      seoPageTitle
      seoShareImage {
        ...ContentfulAssetFragment
      }
      slug
      addCtaBanner {
        ...ModuleCtaBannerFragment
      }
    }
    allContentfulTemplateLeadership(
      filter: {
        partOf: {
          in: [
            "Strategy Council, Board of Directors Subcommittee"
            "Board of Directors"
          ]
          ne: null
        }
      }
      sort: { fields: [partOf, order], order: ASC }
    ) {
      # allContentfulTemplateLeadership(
      #  filter: { fullName: { ne: null }, partOf: { ne: null } }
      #  sort: { fields: lastName }
      # ) {
      nodes {
        contentful_id
        __typename
        slug
        order
        fullName
        partOf
        jobTitle {
          raw
        }
        picture {
          description
          gatsbyImageData(width: 300, formats: JPG)
          # fluid(maxWidth: 300, toFormat: JPG) {
          #  ...GatsbyContentfulFluid
          # }
        }
      }
    }
  }
`;
